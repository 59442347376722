/**
 * All route definitions should be placed here. This object will allow us
 * to easily select the correct route to navigate to while also ensuring
 * that we do not supply any incorrect routes.
 */
export const routes = {
  index: '/',
  about: '/about',
  faq: '/faq',
  nutrition: '/nutrition',
  termsAndConditions: '/terms-and-conditions',
  privacyPolicy: '/privacy-policy',
  auth: {
    login: '/auth/login',
    loginManually: '/auth/login/manual',
    register: '/auth/register',
    registerSendLink: '/auth/register-send-link',
    magicLinkSent: '/auth/magic-link-sent',
    verify: '/auth/verify',
    setSession: '/auth/set-session',
    logout: '/auth/logout',
    /** this route is used to set a password on sign in if no passwords are set */
    setPassword: '/auth/set-password',
    /** This route is used to request a password reset */
    forgotPassword: '/auth/forgot-password',
    /** This route is to set a new password AFTER a request to reset */
    resetPassword: '/auth/reset-password',
    confirm: '/auth/confirm',
  },
  admin: {
    index: '/admin',
    dashboard: '/admin/dashboard',
    coupons: {
      index: '/admin/coupons',
      create: '/admin/coupons/create',
      view: (id: number) => `/admin/coupons/${id}` as const,
    },
    menu: '/admin/menu',
    settings: {
      index: '/admin/settings',
      shipping: {
        view: (id: string) => `/admin/settings/shipping/${id}` as const,
        create: '/admin/settings/shipping/create',
        schedule: {
          create: (id: string) =>
            `/admin/settings/shipping/${id}/schedule/create` as const,
        },
      },
    },
  },
  cart: {
    index: '/cart',
  },
  checkout: {
    index: '/checkout',
    payment: '/checkout/payment',
    addExtras: '/checkout/add-extras',
  },
  order: {
    confirmation: (orderId: string) =>
      `/order/${orderId}/confirmation` as const,
  },
  contact: {
    index: '/contact',
    thankYou: '/contact/thank-you',
  },
  orderNow: {
    index: '/order-now',
    checkDelivery: '/order-now/check-delivery',
    quickOrder: {
      index: '/order-now/quick-order',
    },
    setMenu: {
      index: '/order-now/menu',
      selectYourMeals: '/order-now/menu/select-your-meals',
    },
    createYourOwn: {
      index: '/order-now/create-your-own',
      selectYourMeals: '/order-now/create-your-own/select-your-meals',
    },
    snacksAndJuices: {
      index: '/order-now/snacks-and-juices',
    },
  },
  account: {
    dashboard: '/account/dashboard',
    orders: {
      index: '/account/orders',
      view: (id: string) => `/account/orders/${id}` as const,
      addToCart: (id: string) => `/account/orders/${id}/add-to-cart` as const,
    },
    details: {
      index: '/account/details',
      personalDetails: '/account/details/personal-details',
      addresses: {
        new: '/account/details/addresses/new' as const,
        edit: (id: string) => `/account/details/addresses/${id}/edit` as const,
      },
    },
    paymentMethods: {
      index: '/account/payment-methods',
    },
    subscriptions: {
      index: '/account/subscriptions',
      view: (id: string) => `/account/subscriptions/${id}` as const,
      resubscribe: (id: string) =>
        `/account/subscriptions/${id}/resubscribe` as const,
      referralCode: {
        add: (id: string) =>
          `/account/subscriptions/${id}/referral-code/add` as const,
        change: (id: string) =>
          `/account/subscriptions/${id}/referral-code/change` as const,
        remove: (id: string) =>
          `/account/subscriptions/${id}/referral-code/remove` as const,
      },
    },
  },
} as const;
