import type { LinksFunction } from '@remix-run/node';
import {
  data,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useFetchers,
  useLoaderData,
  useLocation,
  useNavigation,
  useRouteError,
} from '@remix-run/react';
import type { ErrorBoundaryComponent } from '@remix-run/react/dist/routeModules';
import type { LoaderFunctionArgs } from '@remix-run/router';
import { isRouteErrorResponse } from '@remix-run/router';
import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix';

import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { useEffect, useMemo } from 'react';
import { Toaster } from 'sonner';

import sonner from '~/sonner.css?url';

import { Page404 } from './components/page/404';
import { useNotification } from './hooks/useNotification';
import { checkEnvironmentSetup, is } from './services/app';
import { db } from './services/db.server';
import './tailwind.css';
import type { Notification } from './types/notification';
import { cn } from './utils/css';
import { commitSession, getSession } from './utils/sessions';

/**
 * Define any head links that need to be present on every page. This should
 * include stylesheets and any tracking codes.
 *
 * @returns The list of links that should be added to the head tag.
 */
export const links: LinksFunction = () => [
  ...(process.env.NODE_ENV === 'development'
    ? [{ rel: 'stylesheet', href: sonner }]
    : []),
  { rel: 'preconnect', href: 'https://fonts.googleapis.com', as: 'style' },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap',
  },
];

export const ErrorBoundary: ErrorBoundaryComponent = () => {
  const error = useRouteError();

  captureRemixErrorBoundaryError(error);

  if (isRouteErrorResponse(error)) {
    return <Page404 error={error} />;
  } else if (error instanceof Error) {
    return <div>{error.message}</div>;
  }

  return <div>Error: close the website and open it again</div>;
};

export const loader = async ({ request }: LoaderFunctionArgs) => {
  checkEnvironmentSetup();

  // Make sure that we can access the database and that it is set up properly.
  // If not, then we need to prevent access to the application as it cannot
  // be used without an active connection to the database.
  try {
    await db.deliveryPostcodes.count({ where: { postcode: '5000' } });
  } catch (error: unknown) {
    console.error(
      "Database doesn't seem to be working correctly! Do we have a connection and have migrations been run?"
    );

    console.error(error);

    const statusTextFriendly = !is('production')
      ? "Couldn't connect to the database. Are there connection issues?"
      : 'Something went wrong on our side. Please try again later.';

    throw new Response(
      JSON.stringify({
        statusTextFriendly,
      }),
      {
        status: 500,
        statusText: 'Internal Server Error',
      }
    );
  }

  const session = await getSession(request.headers.get('Cookie'));
  const notification = session.get('notification') as Notification | undefined;

  const resp = {
    ENV: {
      SENTRY_ENVIRONMENT: process.env.SENTRY_ENVIRONMENT ?? '',
      SENTRY_DSN: process.env.SENTRY_DSN ?? '',
      STRIPE_PUBLISHABLE_KEY: process.env.STRIPE_PUBLISHABLE_KEY ?? '',
    },
    notification,
  };

  return data(resp, {
    headers: { 'Set-Cookie': await commitSession(session) },
  });
};

/**
 * Handle defining the base components and the outlet for any and all sub
 * pages that have been created.
 *
 * @returns The base view that should be rendered.
 */
function App() {
  const data = useLoaderData<typeof loader>();
  const navigation = useNavigation();
  const fetchers = useFetchers();

  /**
   * Combine the state of every fetcher active on the app with the state of
   * the global transition (Link and Form) elements, then use them to
   * determine if the app is in an idle or loading state. We consider
   * both loading and submitting as a loading state to show the
   * progress bar.
   */
  const state = useMemo<'idle' | 'loading'>(() => {
    const states = [
      navigation.state,
      ...fetchers.map((fetcher) => fetcher.state),
    ];

    if (states.every((state) => state === 'idle')) return 'idle';

    return 'loading';
  }, [navigation.state, fetchers]);

  useEffect(() => {
    /**
     * Start the progress bar when we are submitting a form OR loading a page.
     * This ensures that we show a loading bar for basically every request
     * that occurs on each page.
     */
    if (state === 'loading') NProgress.start();

    /**
     * Ensure we complete the progress bar when we are back in an idle state.
     */
    if (state === 'idle') NProgress.done();
  }, [state]);

  /**
   * Ensure that the progress bar displayed at the top of the screen is
   * configured with the options present. Currently, we don't want the
   * spinner to show to keep it cleaner.
   */
  NProgress.configure({ showSpinner: false });

  useNotification(data.notification);

  const { pathname } = useLocation();
  const isAdminPanel = pathname.startsWith('/admin');

  return (
    <html lang='en' className={cn('h-full', isAdminPanel ? '-dark' : '')}>
      <head>
        {/* All meta exports on all routes will go here. */}
        <meta charSet='utf-8' />
        <meta name='viewport' content='width=device-width,initial-scale=1' />

        <meta name='apple-mobile-web-app-title' content='Shape Up Meal Prep' />
        <meta name='application-name' content='Shape Up Meal Prep' />
        <meta name='msapplication-TileColor' content='#ffffff' />
        <meta name='theme-color' content='#ffffff' />

        <Meta />

        {/* Favicon icon images */}
        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href='/apple-touch-icon.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href='/favicon-32x32.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href='/favicon-16x16.png'
        />
        <link rel='manifest' href='/site.webmanifest' />
        <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#5bbad5' />

        <Links />
      </head>

      <body
        className={cn(
          'h-full bg-white dark:bg-zinc-900',
          isAdminPanel ? 'lg:bg-zinc-100 dark:lg:bg-zinc-950' : ''
        )}>
        {/* Handle showing notifcations */}
        <Toaster theme='light' closeButton position='top-right' />

        {/* Child routes go here. */}
        <Outlet />

        {/*
          Manages scroll position for client-side transitions. If you use a
          nonce-based content security policy for scripts, you must provide
          the `nonce` prop. Otherwise, omit the nonce prop as shown here.
        */}
        <ScrollRestoration />

        {/*
          Set all of the PUBLIC environment variables we need for the client
          side libraries. This includes Sentry.io and Stripe. Make sure to
          NEVER include any private environment keys here as they will
          be available publically!!!
        */}
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(data.ENV)}`,
          }}
        />

        {/*
          Script tags go here. If you use a nonce-based content security
          policy for scripts, you must provide the `nonce` prop.
          Otherwise, omit the nonce prop as shown here.
        */}
        <Scripts />
      </body>
    </html>
  );
}

export default withSentry(App);
